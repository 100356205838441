.newsletter-registration {

  .grid-container {
    padding-top: 15px;
    padding-bottom: 15px;

    .component-headline {
      margin-bottom: 15px;
    }

    .component-intro {
      margin-bottom: 15px;
    }

    .component-success {
      background-color: #4CAF50;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 15px;
      border: 1px solid #3e8e41;
    }
  }
  .submit-row {
    padding-top: 0 !important;

    .footnote {
      padding-top: 10px !important;
    }
  }
}