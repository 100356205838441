.boeckmann {
  &-slider:not(.hero-teaser-slider) {
    .slick-dots {

      li {

        button {
          background: none;
          border-bottom-color: $corp-lightgrey;
        }

        &.slick-active {

          button {
            border-bottom-color: $corp-darkgrey;
          }
        }
      }
    }
  }

  &-slider-container {
    position: relative;

    @include breakpoint(small only) {
      .grid-container {
        padding: 0;
      }
    }

    @include breakpoint(xlarge) {
      &:hover, &:focus {
        .slick-list {
          overflow-y: visible;
        }
      }
    }

    .slick-slide {

      @include breakpoint(medium) {
        margin: rem-calc(0 11px 0 11px);
        position:relative;
      }

      figcaption {
        margin: rem-calc(10px 0 0 15px);
        font-family: $font-roman;
        font-size: rem-calc(12);
        line-height: 1.67;
        color: $corp-darkgrey;
        @include breakpoint(medium) {
          margin: rem-calc(10px 0 0 0);
        }

      }
    }

    @include breakpoint(large) {
      .slick-arrow {
        opacity: 0;
        transition: opacity .5s ease-in;
      }

      .slick-prev-before, .slick-next-after {
        position: absolute;
        z-index: 100;
        display: block;
        height: 100%;
        width: 100%;
        cursor: pointer;
        background: transparent;
        top: 0;
      }

      .slick-prev-before {
        left: -100%;
      }
      .slick-next-after {
        right: -100%;
      }

      .slick-list {
        overflow: visible;
      }

      &:hover, &:focus {

        .slick-arrow {
          opacity: 0.9;
        }
      }
    }
  }
}

.hero-teaser,
.p-d-added-value-hero {

  &-slider {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: rem-calc(70);

    &:focus {
      outline: none;
    }

    @include breakpoint(medium) {
      padding-bottom: 0;
    }

    .slick-dots {
      bottom: rem-calc(20);

      @include breakpoint(medium) {
        bottom: rem-calc(20);
      }
      @include breakpoint(large) {
        bottom: rem-calc(40);
      }

    }

    @include breakpoint(medium) {
      .slick-arrow {
        opacity: 0;
        transition: opacity .5s ease-in;
      }

      &:hover, &:focus {

        .slick-list {
          //overflow: visible;
        }

        .slick-arrow {
          opacity: 0.9;
        }
      }
    }

    .slick-prev {
      left: 5vw;
    }

    .slick-next {
      right: 5vw;
    }

    a {

      &.hero-teaser-slide {

        &:focus {
          outline: none;
        }
      }
    }

  }

  &-slide-container {
    position: relative;
    width: 100%;
    height: 100%;
    @include responsive-size($hero-teaser-slider-height);
    background: transparent center center no-repeat;
    background-size: contain;
  }

  &-slide {
    margin-left: -15px;

    @include breakpoint(medium) {
      margin-left: 0;
    }
  }

  &-mobile-image {
    width: 100vw;
  }

  &-layer {

    position: relative;
    top: 0;
    padding: rem-calc(19px 15px 17px 15px);

    @include breakpoint(medium) {
      position: absolute;
      top: 54px;
      right: -131px;
      padding: rem-calc(19px 146px 17px 29px);
    }

    background: $corp-darkred;
    color: $white;
    font-family: $font-bold-condensed;

    @include responsive-size(null, $hero-teaser-layer-width);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      font-size: rem-calc(28);
      line-height: 1.86;
      font-family: $font-bold-condensed;
      position: relative;
      margin-bottom: rem-calc(35);

      &:after {
        content: '';
        width: 100%;
        background: $white;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -10px;
      }
    }

    p {
      font-size: rem-calc(14);
      line-height: 1.43;
      font-family: $font-roman;
    }
    a {
      color: $white;
    }
  }

  &-bulletlist {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      font-size: rem-calc(14);
      line-height: 1.43;
      font-family: $font-roman;
    }
  }

  &-link {
    margin-top: rem-calc(30);
    font-size: rem-calc(16);
    text-align: right;
    line-height: 1.25;
    font-family: $font-bold;
    align-self: flex-end;
    &:after {
      @include css-triangle-extended(4px, $white, 'right');
      margin-left: 5px;
    }
  }

  @include breakpoint(small down) {
    &.no-mw {
      .hero-teaser-slide-container {
        display: flex;
        flex-direction: column;
        .hero-teaser-mobile-image {
          order: -1;
        }
      }
      .hero-teaser-layer {
        h3 {
          line-height: 1.125;
          padding: 6px 0 4px;
        }
      }
    }
  }

  @include breakpoint(medium) {
    &.no-mw {
      padding-bottom: 80px;
      .hero-teaser-slide-container {
        height: 100%;
      }
      .hero-teaser-layer {
        width: 100%;
        position: relative;
        top: auto;
        right: auto;
      }
      .slick-dots {
        top: 100%;
        bottom: auto;
        margin-top: 15px;
        li {
          height: 24px;
        }
      }
    }
  }

}

.image-slider {

  @include breakpoint(xlarge) {
    &-fadeout {
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10vw;
      z-index: 98;

      &.fade-left {
        left: 0;
        background-image: linear-gradient(to right, $white 56%, rgba(255, 251, 251, 0));
      }

      &.fade-right {
        right: 0;
        background-image: linear-gradient(to right, rgba(255, 251, 251, 0), $white 44%);
      }
    }
  }

  &-container {

    .slick-slide {

      @include breakpoint(medium) {
        margin: rem-calc(0 11px 0 11px);
        position:relative;
      }

      figcaption {
        margin: rem-calc(10px 0 0 15px);
        font-family: $font-roman;
        font-size: rem-calc(12);
        line-height: 1.67;
        color: $corp-darkgrey;
        @include breakpoint(medium) {
          margin: rem-calc(10px 0 0 0);
        }

      }
    }

    @include breakpoint(xxlarge) {

      .outer {
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 30px;
        max-width: rem-calc(1440);
        overflow: hidden;

        &:before, &:after {
          content: '';
          position: absolute;
          top: -3px;
          left: 0;
          height: 100%;
          width: 10%;
          background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 90%);
          z-index: 1;
        }

        &:after {
          left: auto;
          right: -1px;
          transform: rotate(180deg);
        }

      }
    }

  }

  &-slide {
    position: relative;
  }

  .slick-dots {

    li {
      height: 23px;

      &.slick-active {

        button {
          height: 17px;
        }
      }
    }
  }
}

//product details 'added values hero slider'
.p-d-added-value-hero-slider {
  padding-bottom: 20px;

  &.teaser-row.hero-teaser {
    height: initial;
    padding-top: rem-calc(4);

    .slick-arrow {
      opacity: 1;
    }

    @include breakpoint(medium) {
      padding-top: rem-calc(24);
    }

    @include breakpoint(large) {
      height: 746px;

      .slick-dots {
        bottom: rem-calc(72);
      }
    }
  }

  .boeckmann-slider {

    .slick-dots {

      li {
        height: 23px;

        button {
          border-bottom-color: $white;
        }

        &.slick-active {

          button {
            height: 17px;
            border-bottom-color: $corp-darkred;
          }
        }
      }
    }
  }
  .hero-teaser-slide {
    margin-left: 0;
  }

  .hero-teaser-layer {
    padding-bottom: rem-calc(66);
  }

  @include breakpoint(large) {
    padding-bottom: 0;
  }

  @include breakpoint(small down) {

    .hero-teaser-slide-container {
      margin: 0 -15px;
      width: 100%;
    }
  }
}

.p-d-added-value-double-slider {

  .hero-teaser-slide {
    margin-left: 0;
  }

  .slick-prev, .slick-next {
    top: 22%;
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }

  @include breakpoint(medium) {

    .slick-prev {
      left: 45px;
    }

    .slick-next {
      right: 45px;
    }

  }

  @include breakpoint(large) {

    .slick-prev, .slick-next {
      top: 38%;
    }

    .slick-prev {
      left: 116px;
    }

    .slick-next {
      right: 116px;
    }

    .slick-dots {
      bottom: -10px;

      li {
        height: 23px;

        button {
          border-color: $corp-lightgrey;
        }

        &.slick-active {

          button {
            height: 17px;
            border-color: $corp-darkgrey;
          }
        }
      }
    }
  }
}

.offer-teaser-new {

  .slick-slide {
    margin: 0 0 30px;
  }

  .slick-prev, .slick-next {
    top: 28%;
  }

  .slick-prev {
    left: -10px;
  }

  .slick-next {
    right: -10px;
  }

  .slick-dots {
    bottom: 30px;

    li {
      height: 23px;

      button {
        border-color: $corp-lightgrey;
      }

      &.slick-active {

        button {
          height: 17px;
          border-color: $corp-darkgrey;
        }
      }
    }
  }

  @include breakpoint(medium) {

    .slick-slider {

      &.offer {
        padding-bottom: 60px;
      }
    }

    .slick-slide {
      margin: 0 10px;
    }

    .slick-prev, .slick-next {
      top: 38%;
    }

    .slick-prev {
      left: 10px;
    }

    .slick-next {
      right: 10px;
    }

  }

  @include breakpoint(large) {

    .slick-prev, .slick-next {
      top: 38%;
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
    }

    .slick-arrow {
      opacity: 0;
      transition: opacity .5s ease-in;
    }

    .outer:hover {

      .slick-arrow {
        opacity: 0.9;
      }
    }
  }

}
